body {
  margin: 0;
  font-family: "Verdana";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f1f1f1;
}

code {
  font-family: "Verdana";
}

.ms-Callout-beak,
.ms-Callout-main {
  background: #ffffff !important;
}

.react-daterange-picker {
  display: inline-flex;
  position: relative;
  height: 48px;
}
.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-daterange-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: 1px solid rgb(159, 159, 159) !important;
  border-radius: 8px;
}
.react-daterange-picker__wrapper:hover {
  border: 1px solid rgb(42, 42, 42) !important;
}
.react-daterange-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  height: 100%;
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
  margin-left: 4px;
}
.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-daterange-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-daterange-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-daterange-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-daterange-picker__button:enabled {
  cursor: pointer;
}
.react-daterange-picker__button:enabled:hover
  .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus
  .react-daterange-picker__button__icon {
  stroke: #0078d7;
}
.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
  stroke: #6d6d6d;
}
.react-daterange-picker__button svg {
  display: inherit;
}
.react-daterange-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-daterange-picker__calendar--closed {
  display: none;
}
.react-daterange-picker__calendar .react-calendar {
  border-width: thin;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: "Verdana";
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
.rdrCalendarWrapper.rdrDateRangeWrapper {
  width: 500px !important;
}

/* 
  Print Projects 
==========================*/

#print-projects .ms-DetailsHeader-cell:first-child,
#print-projects .ms-DetailsHeader-cell:first-child .ms-DetailsHeader-cellTitle {
  padding-left: 0px;
}

#print-projects .ms-DetailsHeader-cellName,
#print-projects .ms-DetailsRow-cell {
  font-size: 12px !important;
}

#print-projects .ms-DetailsHeader {
  height: 23px;
  border-bottom: none;
  line-height: 28px;
  background-color: transparent;
}

#print-projects .ms-DetailsRow {
  border-bottom: none;
  min-height: auto;
  background-color: transparent;
  width: 100% !important;
}

#print-projects .ms-DetailsRow > div {
  width: 100% !important;
}

#print-projects .ms-DetailsHeader-cell {
  height: auto;
  min-width: 16.7% !important;
  max-width: 16.7% !important;
}

#print-projects .ms-DetailsRow-cell {
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: auto;
  min-width: 16.7% !important;
  max-width: 16.7% !important;
}

#print-projects .ms-DetailsHeader-cell:first-child {
  min-width: 10.7% !important;
  max-width: 10.7% !important;
}

#print-projects .ms-DetailsRow-cell:first-child {
  padding-left: 0px;
  min-width: 10.7% !important;
  max-width: 10.7% !important;
}

#print-projects .ms-DetailsHeader-cell:nth-child(7) {
  min-width: 22.7% !important;
  max-width: 22.7% !important;
}

#print-projects .ms-DetailsRow-cell:nth-child(4) {
  min-width: 22.7% !important;
  max-width: 22.7% !important;
  display: flex;
  flex-wrap: wrap;
  white-space: initial;
}

/* 
  Tag Picker 
==========================*/

.ms-BasePicker .ms-BasePicker-text {
  min-height: 20px;
  border: none;
}

.ms-BasePicker input {
  height: 20px;
  padding-left: 0px;
}

.ms-BasePicker input::placeholder {
  color: rgb(159, 159, 159);
}

.ms-BasePicker .ms-BasePicker-text::after {
  display: none;
}

.ms-TagItem {
  font-size: 11px;
  background-color: #dfdfdf;
  border-radius: 4px;
}

.ms-BasePicker .ms-Button-icon {
  font-size: 8px;
  color: #000;
  font-weight: bold;
}

.ms-BasePicker-itemsWrapper {
  margin-top: 4px;
  margin-bottom: 4px;
}

#print-projects .ms-DetailsList {
  overflow: hidden;
}

/* 
  Team Modal
==========================*/

.radioGroup .ms-ChoiceFieldLabel {
  font-size: 11px;
  letter-spacing: 1px;
}

.ms-TeachingBubble,
.ms-Callout-main,
.ms-Callout-beakCurtain {
  border-radius: 8px;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

/* keyframes */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Custom tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  background-image: linear-gradient(#a8c9dc, #a8c9dc);
  background-repeat: no-repeat;
  background-size: 16px 20px;
  background-position-y: 8px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  color: black;
  text-align: center;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 13%) 0px 3.2px 7.2px 0px,
    rgb(0 0 0 / 11%) 0px 0.6px 1.8px 0px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.machineFormContainer {
  display: flex;
  /* grid-template-columns: auto auto auto; */
  min-height: 100vh;
  background: #f1f1f1;
  justify-content: center;
  gap: 48px;
  /* width: 320px; */
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .machineFormContainer {
    /* grid-template-columns: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.datePickerArrow {
  color: #006cad;
  font-size: 21px;
  cursor: pointer;
}

.datePickerArrow:hover {
  color: #000;
}
